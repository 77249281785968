import React from "react";

import PageNotFound from "../containers/ErrorPage/PageNotFoundPage";
import { getStaticPropsWithI18n } from "../utils/i18n";

export const getStaticProps = getStaticPropsWithI18n(["errors"]);

export default function PageNotFoundPage() {
  return <PageNotFound />;
}
